<template>
  <div>
    <div style="margin-bottom:20px;">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/roleManagement' }">角色管理</el-breadcrumb-item>
        <el-breadcrumb-item>新增角色</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card class="role_card">
      <div class="title">新增角色</div>
      <el-form ref="roleForm" class="role-form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="角色名称" prop="roleName">
          <el-input v-model="form.roleName" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="roleDesc">
          <el-input v-model="form.roleDesc" maxlength="512"></el-input>
        </el-form-item>
        <el-form-item label="选择部门" prop="departmentName">
          <el-select v-model="form.departmentName" multiple placeholder="请选择部门" style="width: 100%;">
            <el-option v-for="item in deptList" :key="item.deptId" :label="item.deptName" :value="item.deptId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联权限">
          <template v-slot:label><span style="color: rgb(245, 108, 108);">*</span> 关联权限</template>
          <el-tree :data="data" ref="rightTree" show-checkbox node-key="id">
            <template #default="{ data }">
              <span>{{data.name}}</span>
            </template>
          </el-tree>
          <span style="color: rgb(245, 108, 108);">{{showTip ? '请选择关联权限' : ''}}</span>
        </el-form-item>
      </el-form>

      <div class="btn-group">
        <div class="btn submit" @click="submit">提交</div>
        <div class="btn cancel" @click="cancel">取消</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance, ref } from "vue";
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
export default {
  setup() {
    const rightTree = ref();
    const roleForm = ref();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const state = reactive({
      form: {
        roleName: "",
        roleDesc: "",
        departmentName: []
      },
      rules: {
        roleName: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        roleDesc: [{ required: true, message: "请输入角色描述", trigger: "blur" }],
        departmentName: [{ required: true, message: "请选择部门", trigger: "change" }]
      },
      data: [],
      deptList: [],
      currentNodeKey: [],

      showTip: false
    })

    onMounted(() => {
      getDeptList();
      getRightTree()
    })

    const getRightTree = () => {
      proxy.$get('/cts/back/authTree/detail').then((res) => {
        if (res.code === '200') {
          state.data = res.data;
        }
      }).catch((err) => {

      });
    }

    const getDeptList = () => {
      proxy.$get('/cts/back/department/deptAllList').then((res) => {
        if (res.code === '200') {
          state.deptList = res.data;
        }
      }).catch((err) => {

      });
    }

    const submit = () => {
      state.showTip = false;
      let data = {
        authId: rightTree.value.getCheckedKeys().toString(),
        deptId: state.form.departmentName.toString(),
        roleDesc: state.form.roleDesc,
        roleName: state.form.roleName
      }
      roleForm.value.validate((valid) => {
        if (rightTree.value.getCheckedKeys().length === 0) {
          state.showTip = true;
          return;
        }
        if (valid) {
          proxy.$post('/cts/back/role/add', data).then((res) => {
            if (res.code === '200') {
              ElMessage({
                message: `${res.msg}`,
                type: 'success',
              })
              router.push({ path: '/roleManagement' })
            }
          }).catch((err) => {

          });
        }
      })
    }

    const cancel = () => {
      router.push({ path: '/roleManagement' })
    }

    return {
      ...toRefs(state),
      getDeptList,
      submit,
      cancel,
      rightTree,
      roleForm
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
}
.role-form {
  width: 400px;
  padding: 0 20px 0 0;
  margin-top: 20px;
}
.btn-group {
  display: flex;
  padding: 30px 0 0 50px;
  .btn {
    text-align: center;
    width: 100px;
    padding: 5px 0;
    border-radius: 3px;
    cursor: pointer;
    &.submit {
      background: rgb(56, 162, 138);
      color: #fff;
    }
    &.cancel {
      border: 1px solid #ddd;
      margin-left: 20px;
    }
  }
}
</style>